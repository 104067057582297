article {
  header {
    .posttitle {
      margin-top: 0;
      margin-bottom: 0;
      text-transform: none;
      font-size: 1.5em;
      line-height: 1.25;
    }
    .meta {
      margin-top: 0;
      margin-bottom: 1rem;
    }
    .meta * {
      color: $color-accent-3;
      font-size: .85rem;
    }
    .author {
      letter-spacing: .01em;
      font-weight: 700;
    }
    .postdate {
      display: inline;
    }
  }
  .content {
    h2 {
      &:before {
        position: absolute;
        top: -4px;
        left: -1rem;
        color: $color-accent-1;
        content: "#";
        font-weight: bold;
        font-size: 1.2rem;
      }
    }
  }
  .content img,
  .content video {
    display: block;
    margin: auto;
    max-width: 100%;
    height: auto;

    /* http://webdesignerwall.com/tutorials/css-elastic-videos */
    .video-container {
      position: relative;
      overflow: hidden;
      padding-top: 56.25% e;
      // (9/16 * 100)% // 16:9 ratio
      height: 0;

      iframe,
      object,
      embed {
        position: absolute;
        top: 0;
        left: 0;
        margin-top: 0;
        width: 100%;
        height: 100%;
      }
    }
    blockquote {
      margin: 1rem 10px;
      padding: .5em 10px;
      background: inherit;
      color: $color-quote;
      quotes: "\201C" "\201D" "\2018" "\2019";
      font-weight: bold;

      p {
        margin: 0;
      }
      &:before {
        margin-right: .25em;
        color: $color-quote;
        content: "\201C";
        vertical-align: -.4em;
        font-size: 2em;
        line-height: .1em;
      }
      footer {
        margin: line-height 0;
        color: $color-meta;
        font-size: 11px;

        a {
          background-image: linear-gradient(transparent, transparent 5px, $color-meta 5px, $color-meta);
          color: $color-meta;
        }
        a:hover {
          background-image: linear-gradient(transparent, transparent 4px, lighten($color-meta, 20%) 4px, lighten($color-meta, 20%));
          color: lighten($color-meta, 20%);
        }
        cite {
          &:before {
            padding: 0 .5em;
            content: "—";
          }
        }
      }
    }
    .pullquote {
      margin: 0;
      width: 45%;
      text-align: left;

      &.left {
        margin-right: 1em;
        margin-left: .5em;
      }
      &.right {
        margin-right: .5em;
        margin-left: 1em;
      }
    }
    .caption {
      position: relative;
      display: block;
      margin-top: .5em;
      color: $color-meta;
      text-align: center;
      font-size: .9em;
    }
  }
}
.posttitle {
  text-transform: none;
  font-size: 1.5em;
  line-height: 1.25;
}
.article-tag {
  .tag-link {
    &:before {
      content: "#";
      @include underline(10px, $color-link);
    }
  }
}
.article-category {
  .category-link {
    @include underline(10px, $color-link);
  }
}
@media (min-width: 480px) {
  .article-read-time,
  .article-tag,
  .article-category {
    display: inline;

    &:before {
      content: "|";
    }
  }
};
